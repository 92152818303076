.menu-display {
	width: 50px;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 5px;
	position: absolute;
	right: 0px;
	top: 10px;
}
.nav-link:focus {
	color: #fa9001;
}
.hide-menu {
	display: none;
}
.menu-item {
	cursor: pointer;
	padding: 6px;
}
.blue-theme {
	color: #fa9001 !important;
}
.navbar-brand {
	font: normal normal medium 24px/35px;
}

@media (max-width: 767px) {
	.hide-menu {
		display: block;
	}
}
