.loginPageContent {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background-image: linear-gradient(rgba(3, 3, 3, 0.5), rgba(10, 0, 0, 0.5)),
    url("https://leaddetails.s3.amazonaws.com/Mask+Group+6.svg");
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.eye {
  position: absolute;
  top: -35px;
  right: 12px;
}

.signup p {
  padding-bottom: 4%;
  color: #272727;
}

.forgotLink {
  float: right;
  margin-top: -20px;
  font: normal normal medium 20px/30px Poppins;
  letter-spacing: 0px;
}

.logoimg {
  width: 50%;
  margin-right: 25%;
  margin-left: 25%;
  padding-top: 2%;
}
.card {
  border: none;
}
.card-body {
  padding: 0px !important;
}
.signup:hover {
  color: black;
}
